<template>
  <div class="index">

    <van-notice-bar
      v-if="enableEpidemic"
      mode="closeable"
      wrapable
      :scrollable="false"
      :text="epidemicContent"
    />

    <van-notice-bar color="#1989fa" background="#ecf9ff" mode="closeable">
      当前权益次数还剩{{equityCount}}次，活动奖励权益{{award}}次。
    </van-notice-bar>

    <header>
      <van-search
      class="restaurant-search"
      v-model="keyword"
      placeholder="搜索招牌菜"
      @search="onSearch"
    />
    </header>
    
    <van-skeleton title :row="2" :loading="loading" >
      <van-dropdown-menu class="restaurant-filter">
        <van-dropdown-item
          v-model="cityId"
          :options="cities"
          @change="onFilter">
          <template #title>
              <van-icon name="location-o" color="#333" /> {{getOption(cities , cityId)}}
          </template>
        </van-dropdown-item>
        <van-dropdown-item
          v-model="styleId"
          :options="cookingStyles"
          @change="onFilter">
          <template #title>
            <van-icon name="fire-o" color="#333" /> {{getOption(cookingStyles , styleId)}}
          </template>
        </van-dropdown-item>
        <van-dropdown-item
          v-model="priceId"
          :options="prices"
          @change="onFilter">
          <template #title>
            <van-icon name="gold-coin-o" color="#333" /> {{getOption(prices , priceId)}}
          </template>
        </van-dropdown-item>
      </van-dropdown-menu>
    </van-skeleton>
    <!-- <van-skeleton title :row="6" :loading="loading" > </van-skeleton> -->
    <van-list @load="onPullRefresh">
      <section class="specialy-card" v-for="dish in  dishs" :key="dish.id">
          <div class="specialy-header">
              <div class="specialy-name">{{dish.name}}</div>
              <div class="specialy-action">
                <!-- <span class="time-line">距截止</span>
                <van-count-down :time="dish.expirationTimeSeconds" format="HH:mm:ss" /> -->
                <van-button class="btn-order" type="primary" @click="onBuying(dish)" v-if="isOnSale(dish)">购买</van-button>
                <van-button class="btn-order" type="primary" disabled v-else>购买 </van-button>
              </div>
          </div>
          <div class="specialy-detail" @click="onDetails(dish.id)" v-lazy:background-image="backgroundStyleUrl(dish)">
            <div class="specialy-info ho-center">
                <div>
                  <div class="ho-center">
                    <span class="price">¥{{dish.price}}</span> 
                    <span class="ori-price">原价:¥{{dish.originPrice}}</span>
                  </div>
                  <div class="rest-info"><van-icon name="shop-o" /> {{dish.restaurantName}}  {{dish.cookingStyleName}} {{dish.cityName}}</div>
                </div>
                <div class="sale-info">
                  已售 <span class="sale-amount">{{saleRate(dish)}}%</span>
                </div>
            </div>
          </div>    
      </section>
    </van-list>
  </div>

  <Nav active="dish"></Nav>

</template>

<script>
import { reactive, toRefs, onMounted } from "vue";
import { useRouter } from "vue-router";
import { Toast } from 'vant';
import { loading , clearLoading} from "../utils/loading";
import { resetImageUrl} from "../utils";
import Nav from "../components/Nav.vue"
import Controller from "./specialty-dish-controller";
import "../css/restaurant.scss";
import "../css/specialty-dish.scss";

export default {
  components: {
    Nav
  },
  setup() {
    const state = reactive({
      dishs: [],
      cityId: 0,
      cities:[],
      styleId: 0,
      cookingStyles: [],
      priceId: 0,
      prices: [],
      keyword: "",
      loading: true,
      equityCount: 0,
      award: 0,
      pullRefresh: false,
      enableEpidemic: false,
      epidemicContent: "",
    });
    const router = useRouter();
    const onDetails = id => router.push("/dish/" + id);
    const onSearch = () => {
      loading()
      Controller.search(state).then(clearLoading, clearLoading)
    };
    const onFilter = () => {
      loading()
      Controller.filter(state).then(clearLoading, clearLoading)
    };
    const saleRate = dish => Controller.saleRate(dish);
    const isOnSale = dish => Controller.onSale(dish); 
    const getOption = (values, id) => Controller.getOption(values ,id);
    const onBuying = dish => { 
      loading();
      Controller.buying(dish).then(orderId => {
          router.push("/order/" + orderId  + "/paying")
          clearLoading()
        } , err => {
          clearLoading();
          Toast.fail(err.message);
        })
    };
    const createBackgroundStyle = dish => ({ "background-image" : "url(" +  resetImageUrl(dish.imageUrl, 0 , 160) +")"});
    const backgroundStyleUrl = dish => resetImageUrl(dish.imageUrl, 0, 0);
    const onPullRefresh = () => {
        if( state.pullRefresh ) {  //skip
          return ;
        }
        state.pullRefresh = true;
        Controller.pullRefresh(state).then(() => state.pullRefresh = false);
    } 
    onMounted(() => Controller.onMounted(state));
    return {
      ...toRefs(state),
      onDetails,
      onSearch,
      onFilter,
      onPullRefresh,
      saleRate,
      isOnSale,
      onBuying,
      createBackgroundStyle,
      backgroundStyleUrl,
      getOption
    };
  },
};
</script>
